// Author: Gary
// url: /device/:deviceId
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
    PageFrame,
    PageTitle,
    PageTitleContent,
    PageHr,
    dateDecoder,
} from '../PageTemplate';
import {
    TableContainer,
    TableRow,
    TableCol,
    Table
} from '../../components/Table/TableElements';
import {
    BackToMainPageBar,
    BackToMainPageBarContainer,
    BackToMainPageButton,
    ButtonsContainer,
    ButtonsWrapper,
    FunctionMenu,
    PageSwipingContainer,
    ReminderText,
    ReminderTextContainer,
    ReminderTextWrapper,
    SwipingPage
} from './DeviceElements';
import {
    ActivateDialog,
    RegionSelectionDialog,
    InitImgDialog
} from '../../components/Dialog/Dialog';
import Accordion from '../../components/Accordion/Accordion';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Button from '@material-ui/core/Button';
import Card from '../../components/Card/Card';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Gallery from '../../components/Gallery/Gallery';
import IconButton from '@material-ui/core/IconButton';
import ImageIcon from '@material-ui/icons/Image';
import Map from '../../components/Map/Map';
import Modal from '../../components/Modal/Modal';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import OnSuccessSnackbars from '../../components/Snackbar/Snackbar';
import PhotoSizeSelectLargeIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import Row from '../../components/Row/Row';
import SyncIcon from '@material-ui/icons/Sync';
import Tooltip from '@material-ui/core/Tooltip';
import axios from '../../components/axios';
import './Device.css';
// Used to copy the device's data
import CopyToClipborad from 'react-copy-to-clipboard';
import { date } from 'date-fn';
//import CloudUploadIcon from '@material-ui/icons/CloudUpload';

async function checkDevice(deviceId){
    // check device is user or not
    let userDevice = false;
    let userIdCSV = localStorage.getItem('userId');
    try{
        let userId = userIdCSV.split('"');
        userId = userId[1];
        await axios.post(
            '/static/checkUserDevice.php', JSON.stringify({userId , deviceId})
        ).then(
            result => {
                console.log(result.data);
                if (result.data[0].check == "Yes"){
                    userDevice = true
                    global.checked = true;         
                }
            }
        )
    } catch (error){
        console.log(error);
    }
    // return to devices list page if user not have this device
    if (!userDevice){
        window.location.replace("/devices");
        window.alert('Not such device! Return back to Devices List');
    }
    return 0;
}

const Device = () => {
    const { deviceId } = useParams();
    
    const [modalState, setModalState] = useState(false);
    const [selectedImg, setSelectedImg] = useState({});
    const [deviceInfo, setDeviceInfo] = useState({});

    const [deviceImageSource, setDeviceImageSource] = useState([]);
    const [ascendingOrdered, setAscendingOrdered] = useState(false);

    const [filteringCriteria, setFilteringCriteria] = useState(false);
    const resetFilteringCriteria = () => setFilteringCriteria(false);

    const [initImg, setInitImg] = useState(null);
    //const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [regionSelectionDialogOpen, setRegionSelectionDialogOpen] = useState(false);
    const [initImgDialogOpen, setInitImgDialogOpen] = useState(false);
    const [activateDialogOpen, setActivateDialogOpen] = useState(false);

    const [swipingPage, setSwipingPage] = useState(true);
    const toggleSwipingPage = () => setSwipingPage(!swipingPage);

    //const showUploadDialog = () => setUploadDialogOpen(!uploadDialogOpen);
    const showRegionSelectionDialog = () => setRegionSelectionDialogOpen(!regionSelectionDialogOpen);
    const showInitImgDialog = () => setInitImgDialogOpen(!initImgDialogOpen);
    const showActivateDialog = () => setActivateDialogOpen(!activateDialogOpen);

    //SnackBar
    const [onSuccess, setOnSuccess] = useState(false);
    const toggleSnackBar = () => setOnSuccess(!onSuccess);

    const showModal = (info) => {
        setModalState(!modalState);
        setSelectedImg(info);
        console.log(info);
    }

    const redirect = () => {
        window.open(`http://192.168.43.31/?id=${ deviceId }`,"_self");
    };

    const menuItems = [
        {
            display: initImg === null ? false : true,
            icon: <ImageIcon />,
            item: "Initial Image",
            controlFunction: showInitImgDialog
        },
        {
            display: true,
            icon: <PhotoSizeSelectLargeIcon />,
            item: "Adjustable Setting",
            controlFunction: showRegionSelectionDialog
        },
        {
            display: true,
            icon: <BookmarkBorderIcon />,
            item: "Bookmark",
            controlFunction: () => alert("This function is not yet available.")
        },
    ];

    const changeOrder = () => setAscendingOrdered(!ascendingOrdered);

    if (modalState) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
        document.body.style.overflowX = 'hidden'
    }

    if(activateDialogOpen) {
        
    }

    useEffect(() => {
        async function fetchData() {

            global.checked = false;

            let finish = await checkDevice(deviceId);

            try {
                if (global.checked){
                    const info = await axios.get(`/static/getDeviceInfo.php?id=${ deviceId }`);
                    console.log(info.data[0]);
                    setDeviceInfo(info.data[0]);

                    const initImage = await axios.get(`/static/get_initImage.php?id=${ deviceId }`);
                    console.log(initImage.data[0]);
                    if (initImage.data.length > 0) {
                        setInitImg(initImage.data[0]);
                    }
                    
                    const images = await axios.get(`/static/get_imgPaths.php?id=${ deviceId }`);
                    console.log(images.data);  
                    setDeviceImageSource(
                        images.data.map((val, key) => {
                            return (
                                {
                                    device_id: deviceId,
                                    image_index: val.image_index,
                                    path: `https://api.ciratest1.hkrnd.com/static/get_pic.php?path=${ val.file_path }`+"complete_dot.jpg",
                                    date: val.takephoto_date,
                                    receivedDate: val.received_date,
                                    receivedTime: val.received_time,
                                    totalPktNum: val.total_packet_num,
                                    totalPktReceive: val.total_packet_received,
                                    qualityIndex: val.quality_index,
                                    bookmarked: val.bookmarked,
                                    rssi_latest: val.rssi_latest,
                                    snr_latest: val.snr_latest,
                                    temp_latest: val.temp_latest,
                                    humi_latest: val.humi_latest,
                                    regionEnable: val.regionEnable,
                                    discrepancyThreshold: val.discrepancyThreshold
                                }
                            );
                        })
                    );
                }
            } catch (error) {
                console.log('fetch data FAILED.');
                console.log(error);
            }
        }
        fetchData();
    }, [deviceId]);

    const test = deviceInfo;
    const lem = String(test.device_eui)
    
    let result = [];
    for ( let i = 0; i < lem.length; i += 2)
    {
        result.push('0x' + lem.charAt(i) + lem.charAt(i+1));
    }

    return (
        <PageFrame>
            <BackToMainPageBarContainer>
                <BackToMainPageBar>
                    <Tooltip title="Previous Page" placement="right">
                        <Button 
                            style={ { width: '100%', height: '100%', borderRadius: 20 } }
                            onClick={ () => window.location.href = "/devices" }
                        >
                            <ArrowBackIosIcon />
                        </Button>
                    </Tooltip>
                </BackToMainPageBar>
            </BackToMainPageBarContainer>

            <PageTitle>
                <PageTitleContent>
                    <BackToMainPageButton />
                    Device
                    <FunctionMenu menuItems={ menuItems } />
                    
                    <RegionSelectionDialog
                        deviceId={ deviceId }
                        deviceEUI={ deviceInfo.device_eui }
                        dialogOpen={ regionSelectionDialogOpen } 
                        controlFunctions={ [showRegionSelectionDialog, toggleSnackBar] }
                    />
                    <InitImgDialog
                        dialogOpen={ initImgDialogOpen }
                        controlFunctions={ [showInitImgDialog]}
                        imgSrc={ initImg === null ? '' : initImg.file_path }
                        date={ initImg === null ? '' : initImg.date }
                    />
                </PageTitleContent>
                <PageHr />
            </PageTitle>
            

            <ReminderTextContainer>
            {/** this function is to upload an initial image.
             * {
                    !initImage && (
                        <>
                            <ReminderTextWrapper>
                                <ErrorOutlineIcon />
                                <ReminderText>This device does not have an initial image.</ReminderText>
                            </ReminderTextWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={ <CloudUploadIcon /> }
                                onClick={ showUploadDialog }
                            >
                                Upload
                            </Button>
                            <UploadDialog dialogOpen={ uploadDialogOpen } controlFunction={ showUploadDialog } />
                        </>
                    )
                } 
            */}
            {
                initImg === null && (
                    <>
                        <ReminderTextWrapper>
                            <ErrorOutlineIcon />
                            <ReminderText>This device has not been activated.</ReminderText>
                        </ReminderTextWrapper>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={ <SyncIcon /> }
                            onClick={ redirect }
                        >
                            Activate
                        </Button>


                        
                        <ActivateDialog dialogOpen={ activateDialogOpen } deviceId={ deviceId } controlFunction={ showActivateDialog } />
                    </>
                )
            }
            </ReminderTextContainer>

            <Row padding={ '0 50px 20px' } grey={ true } columns={ '1fr 1fr' }>
                
                <Card heading='Details'>
                    <div>
                        <PageSwipingContainer className={ swipingPage ? 'page-moveToRight' : 'page-moveToLeft'}>
                            {/** Page 1 */}
                            <SwipingPage style={{display: swipingPage ? '' : 'none'}}>
                                {/** need to handle the keyError here */}
                                <TableContainer>
                                    <Table style={ { margin: 0 } }>
                                        <TableRow>
                                            <TableCol>Device Name:</TableCol>
                                            <TableCol>{ deviceInfo.device_name }</TableCol>
                                        </TableRow>
                                        <TableRow>
                                            <TableCol>Device EUI:</TableCol>
                                            <TableCol>{ deviceInfo.device_eui }</TableCol>
                                        </TableRow>
                                        <TableRow>
                                            <TableCol>Battery:</TableCol>
                                            <TableCol>{ deviceInfo.battery } %</TableCol>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                            </SwipingPage>

                            {/** Page 2 */}
                            <SwipingPage style={{display: swipingPage ? 'none' : ''}}>
                                {/** need to handle the keyError here */}
                                <TableContainer>
                                    <Table style={ { margin: 0 } }>
                                        <TableRow>
                                            <TableCol>Latitude:</TableCol>
                                            <TableCol>{ deviceInfo.latitude }</TableCol>
                                        </TableRow>
                                        <TableRow>
                                            <TableCol>Longitude:</TableCol>
                                            <TableCol>{ deviceInfo.longitude }</TableCol>
                                        </TableRow>
                                        <TableRow>
                                            <TableCol>Registered on:</TableCol>
                                            <TableCol>{ dateDecoder(deviceInfo.register_date) }</TableCol>
                                        </TableRow>                                        
                                    </Table>
                                </TableContainer>
                            </SwipingPage>
                        </PageSwipingContainer>
                        
                        <ButtonsContainer>
                            <ButtonsWrapper>
                                <IconButton size="small" onClick={ toggleSwipingPage } disabled={ swipingPage }><NavigateBeforeIcon /></IconButton>
                                <IconButton size="small" onClick={ toggleSwipingPage } disabled={ !swipingPage }><NavigateNextIcon /></IconButton>
                                <CopyToClipborad text= {result}>
                                    <button>Copy</button>
                                </CopyToClipborad>
                            </ButtonsWrapper>
                        </ButtonsContainer>
                    </div>
                </Card> 
                <Map sty={ { height: 285 } } endpoint={ `/static/getDeviceInfo.php?id=${ deviceId }` } />             
            </Row>

            {/** the expandable filtering bar */}
            <Accordion controlFunctions={ [changeOrder, setFilteringCriteria]} />

            {/** showing all received images */}
            <Gallery 
                imageSource={ deviceImageSource } 
                controlFunction={ showModal } 
                ascending={ !ascendingOrdered } 
                filteringCriteria={ filteringCriteria } 
                resetFiltering={ resetFilteringCriteria } 
            />
            
            {/** when an image is click, it opens the modal to display the enlarged image */}
            <Modal showModal={ modalState } controlFunction={ showModal } selectedImg={ selectedImg } />    

            <OnSuccessSnackbars open={ onSuccess } controlFunction={ toggleSnackBar }>
                Setting changed, it will take effect in two images .
            </OnSuccessSnackbars>
        </PageFrame>
    )
}

export default Device