import { React, useState } from 'react';
import Row from '../../components/Row/Row';
import {
    PageFrame,
    PageTitle,
    PageHr
} from '../PageTemplate';
import HelpOutlineTwoToneIcon from '@material-ui/icons/HelpOutlineTwoTone';
import TabbasedSection from './RegisterationElements';
import { QRCodeDialog } from '../../components/Dialog/Dialog';

function Registeration(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [displayInfo, setDisplayInfo] = useState({});

    const toggle = () => setDialogOpen(!dialogOpen);
    const useDisplayInfo = (info) => setDisplayInfo(info);

    return (
        <PageFrame>
            <PageTitle>
                Registeration <HelpOutlineTwoToneIcon/>
                <PageHr />
            </PageTitle>

            <Row grey={ true }>
                <TabbasedSection controlFunctions={ [toggle, useDisplayInfo, props.controlFunction ] } />
            </Row>
            
            <QRCodeDialog 
                dialogOpen={ dialogOpen } 
                controlFunction={ toggle }
                displayInfo={ displayInfo }
            />
        </PageFrame>
    );
}

export default Registeration
