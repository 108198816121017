// Reference: https://material-ui.com/components/dialogs/
// Author: Gary
import { React, useState, useEffect, useRef } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import {
    ActivationAppFrame,
    ActivationAppFrameContainer,
    ActivationAppFrameWrapper,
    PreviewImage,
    PreviewImageContainer,
    PreviewImageInputContainer,
    QRCodeDisplayContainer,
    RegionPickerContainer,
    StepperWrapper,
    TitleContainer,
    TitleIcon,
    WarningMessage
} from './DialogElements';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IconButton from '@material-ui/core/IconButton';
import Image from '../Gallery/Image/Image';
import ImageIcon from '@material-ui/icons/Image';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PhotoSizeSelectLargeIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import ProgressStepper from '../Stepper/Stepper';
import RegionPicker from '../RegionPicker/RegionPicker';
import SyncIcon from '@material-ui/icons/Sync';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from '../../components/axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {
                onClose 
                    ? 
                        (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                        ) 
                    : 
                        null
            }
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

// show a QR code of a newly created device
export function QRCodeDialog(props) {
    return (
        <div>
            <Dialog onClose={props.dialogOpen} open={props.dialogOpen}>
                <DialogTitle onClose={props.controlFunction}>
                    Create New Device
                </DialogTitle>
                
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Save this registration QR code to your device and stick it onto the CIRA device.
                    </Typography>

                    { /** QR code */ }
                    <QRCodeDisplayContainer>
                        <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${props.displayInfo['device_eui']}_%_${props.displayInfo['device_name']}`} alt='' />    
                    </QRCodeDisplayContainer>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={props.controlFunction} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// temporarily unused
export function UploadDialog(props) {
    const uploadedImageRef = useRef(null);
    const [previewImage, setPreviewImage] = useState("");
    const previewUploadedImage = () => setPreviewImage(URL.createObjectURL(uploadedImageRef.current.files[0]));
    
    return (
        <div>
            <Dialog onClose={props.dialogOpen} open={props.dialogOpen} scroll={'body'}>
                <DialogTitle onClose={props.controlFunction}>
                    <TitleContainer>
                        <TitleIcon>
                            <CloudUploadIcon />
                        </TitleIcon>
                        Upload an initial image
                    </TitleContainer>
                </DialogTitle>
                
                <DialogContent dividers>
                    <Typography gutterBottom>
                        An initial image of the device is used to optimize the recovery progress of images.
                        Upload an initial image press CONFIRM &amp; UPLOAD button.
                    </Typography>
                    
                    <PreviewImageInputContainer>
                        <input type="file" id="img" name="img" ref={ uploadedImageRef } onChange={ previewUploadedImage } accept="image/*" />
                    </PreviewImageInputContainer>
                    
                    <PreviewImageContainer>
                        <PreviewImage src={previewImage} alt={""} />
                    </PreviewImageContainer>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={props.controlFunction} color="primary">
                        CONFIRM &amp; UPLOAD
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// to upload an initial image
export function ActivateDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const deviceId = props.deviceId;
    
    return (
        <div>
            <Dialog 
                onClose={props.dialogOpen} 
                open={props.dialogOpen} 
                scroll={'body'}
                fullScreen={fullScreen}
                maxWidth={'lg'}
                style={{ zIndex: 10000}}
            >
                <DialogTitle onClose={props.controlFunction}>
                    <TitleContainer>
                        <TitleIcon>
                            <SyncIcon />
                        </TitleIcon>
                        Activate the Device
                    </TitleContainer>
                </DialogTitle>
                
                <DialogContent dividers>
                    {
                        /** 
                        <Typography gutterBottom>
                            To activate the device, you need to capture an initial image with the camera. Please follow the steps below to complete the activation.
                        </Typography>
                        */
                    }
                    <ActivationAppFrameContainer>
                        <StepperWrapper>
                            <ProgressStepper deviceId={ deviceId }/>
                        </StepperWrapper>
                        { /** IP address of the CIRA node */ }
                        {/* <ActivationAppFrameWrapper>
                            <ActivationAppFrame id='activation-app' src={ `https://192.168.43.31/?id=${ deviceId }` } />
                        </ActivationAppFrameWrapper> */}
                    </ActivationAppFrameContainer>   
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={props.controlFunction} color="primary">
                        CONFIRM &amp; UPLOAD
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// to select a targeted region
export function RegionSelectionDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [imageInfo, setImageInfo] = useState(null);

    // if the device already has at least one received image, use the latest received one for the region selection
    // if the device is just activated, use the initial image instead (not yet implemented)
    useEffect(() => {
        async function fetchData() {
            try {
                const info = await axios.get(`/static/getLatestDeviceInfo.php?id=${ props.deviceId }`);
                if (info.data.length > 0) {
                    console.log(info.data[0]);
                    setImageInfo(info.data[0]);
                }      
            } catch (error) {
                console.log('fetch data FAILED.');
                console.log(error);
            }
        }
        fetchData();
    }, [props.deviceId]);

    return (
        <div >
            <Dialog 
                onClose={props.dialogOpen} 
                open={props.dialogOpen} 
                scroll={'body'}
                fullScreen={fullScreen}
                maxWidth={'lg'}
                style={{top: 80}}
            >
                <DialogTitle onClose={props.controlFunctions[0]}>
                    <TitleContainer>
                        <TitleIcon>
                            <PhotoSizeSelectLargeIcon />
                        </TitleIcon>
                        Selecting Targeted Region
                    </TitleContainer>
                </DialogTitle>

                <DialogContent dividers>
                {
                    imageInfo !== null && (
                        <>
                            <Typography gutterBottom>
                                You can specify a targeted capturing region of the received images. 
                            </Typography>
                            <Typography gutterBottom>
                                <strong>Note</strong>: The adjusting unit for the width and height is 5 MCU.
                            </Typography>
                            <RegionPickerContainer>
                                <RegionPicker 
                                    controlFunctions={props.controlFunctions}
                                    imagepath={imageInfo.file_path}
                                    deviceEUI={props.deviceEUI} />
                            </RegionPickerContainer>
                        </>
                    )
                }
                {
                    imageInfo === null && (
                        <TitleContainer>
                            <TitleIcon>
                                <ErrorOutlineIcon />
                            </TitleIcon>
                            Please upload an inital image for this device before you use this function. Or you should wait for the received images.
                        </TitleContainer>
                    )
                }
                </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={props.controlFunctions[0]} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// to rename a newly created device (in registration - create new device - page)
export function RenameDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [newDeviceName, setNewDeviceName] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    const typeNewDeviceName = (event) => setNewDeviceName(event.target.value);
    const reset = () => {
        setNewDeviceName('');
        setWarningMessage('');
    };

    const confirmNewDeviceName = () => {
        if (newDeviceName === '') {
            setWarningMessage('The device name cannot be blank.')
        } else {
            try {
                let payload = {
                    name: newDeviceName
                }
                // when the Confirm button is clicked, check the database if the name has already been use
                // also check the name pattern (no whitespace, special character, etc.)
                axios.post(`/static/verifyDeviceName.php`, JSON.stringify(payload)).then(
                    (info) => {
                        if (info.data === 'ok') {
                            setWarningMessage('');
                            let payload = {
                                name: newDeviceName,
                                device_eui: props.deviceInfo.device_eui
                            }
                            axios.post(`/static/updateNewDeviceName.php`, JSON.stringify(payload)).then(
                                (result) => {
                                    if (result.data === 'ok') {
                                        props.controlFunctions[1](props.deviceInfo.key, newDeviceName);
                                        props.controlFunctions[0]();
                                        reset();
                                    }
                                }
                            )
                        } else if (info.data === 'incorrect pattern') {
                            setWarningMessage('The name may only contain words, numbers and dashes.')
                            console.log('incorrect pattern');

                        } else if (info.data === 'exists') {
                            setWarningMessage('the name has already been taken.')
                            console.log('exists');
                        }
                    }
                );
            } catch (error) {
                console.log('Name verification FAILED.');
                console.log(error);
            }
        }
    }

    return (
        <div >
            <Dialog 
                onClose={props.dialogOpen} 
                open={props.dialogOpen} 
                scroll={'body'}
                fullScreen={fullScreen}
                maxWidth={'lg'}
                style={{top: 80}}
            >
                <DialogTitle onClose={props.controlFunctions[0]}>
                    <TitleContainer>
                        <TitleIcon>
                            <EditOutlinedIcon />
                        </TitleIcon>
                        Set New Device Name
                    </TitleContainer>
                </DialogTitle>
                
                <DialogContent dividers>
                    <Typography gutterBottom>
                        You may rename the device. After the device is registered, you cannot change the device name.
                    </Typography>

                    <Typography gutterBottom>
                        <strong>Note</strong>: The name may only contain words, numbers, and dashes.
                    </Typography>
        
                    <TextField fullWidth label="New device name" value={newDeviceName} onChange={ typeNewDeviceName } />

                    <WarningMessage>
                        { warningMessage }
                    </WarningMessage>
                </DialogContent>
                
                <DialogActions>
                    <Button autoFocus onClick={ reset } color="primary">
                        RESET
                    </Button>
                    <Button autoFocus onClick={ confirmNewDeviceName } color="primary">
                        CONFIRM
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// to show an initial image of a device
export function InitImgDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    return (
        <div >
            <Dialog 
                onClose={props.dialogOpen} 
                open={props.dialogOpen} 
                scroll={'body'}
                fullScreen={fullScreen}
                maxWidth={'lg'}
                style={{top: 80}}
            >
                <DialogTitle onClose={props.controlFunctions[0]}>
                    <TitleContainer>
                        <TitleIcon>
                            <ImageIcon />
                        </TitleIcon>
                        Initial Image
                    </TitleContainer>
                </DialogTitle>
                
                <DialogContent dividers>
                    <Typography gutterBottom>
                        The initial image is captured when the device was activated. 
                    </Typography>
                    <Typography gutterBottom>
                        Date: {props.date}
                    </Typography>
                    <RegionPickerContainer>
                        <Image src={ `https://api.ciratest1.hkrnd.com/static/get_pic.php?path=${ props.imgSrc }` } staticImage={ true } />
                    </RegionPickerContainer>
                </DialogContent>
                
                <DialogActions>
                    <Button autoFocus onClick={props.controlFunctions[0]} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}