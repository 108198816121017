import React from 'react';
import { SmallCard } from '../../components/Card/Card';
import Map from '../../components/Map/Map';
import Row from '../../components/Row/Row';
import {
    PageFrame,
    PageTitle,
    PageHr
} from '../PageTemplate';
import {
    InfoCard,
    InfoCardsContainer
} from './HomeElements';
import RouterIcon from '@material-ui/icons/Router';
import ImageIcon from '@material-ui/icons/Image';
import HttpIcon from '@material-ui/icons/Http';
import { green, blue, pink, yellow } from '@material-ui/core/colors';
import './Home.css';
//change
import axios from '../../components/axios';
import { useState, useEffect } from 'react';

function HomePage() {

    const [deviceInfo, setDeviceInfo] = useState({});
    useEffect(() => {
        async function fetchData() {
            try {
                const info = await axios.get(`/static/getDeviceInfo.php?id=${ 5 }`);
                console.log(info.data[0]);
                setDeviceInfo(info.data[0]);
            } catch (error) {
                console.log('fetch data FAILED.');
                console.log(error);
            }
        }
        fetchData();
    }, [5])

    return (
        <PageFrame>
            <PageTitle>
                Home    
                <PageHr />
            </PageTitle>

            {/** temporarily hard-code */}
            <InfoCardsContainer>
                <SmallCard>
                    <InfoCard 
                        icon={ [<RouterIcon />, green[500]] }
                        heading='Number of Devices'
                        
                        figure={localStorage.getItem('deviceNumber')}
                    />
                </SmallCard>

                <SmallCard>
                    <InfoCard 
                        icon={ [<ImageIcon />, blue[500]] }
                        heading='Number of Images'
                        figure='2048'
                    />
                </SmallCard>

                <SmallCard>
                    <InfoCard 
                        icon={ [<ImageIcon />, yellow[800]] }
                        heading='Latest Image'
                        figure='55'
                    />
                </SmallCard>

                <SmallCard>
                    <InfoCard 
                        icon={ [<HttpIcon />, pink[500]] }
                        heading='HTTP Requests'
                        figure='510'
                    />
                </SmallCard>
            
            </InfoCardsContainer>
            

            <Row grey={ true } columns={ '3fr' }>
                <Map endpoint='/static/getDeviceInfo.php' />
            </Row>
        </PageFrame>
    );
}

export default HomePage
