// Reference: https://material-ui.com/components/accordion/
import React, { useState } from 'react';
import {
    AccordionClickBox,
    AccordionContainer, 
    AccordionExpand, 
    AccordionWrapper,
    SectionTitle,
    SortnFilterContainer
} from './AccordionElements';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider,} from '@material-ui/pickers';
import { StaticPickers } from '../Input/Input'

const Accordion = (props) => {
    const [expand, setExpand] = useState(false);
    const trigger = () => setExpand(!expand);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}> 
            <AccordionContainer>
                <AccordionWrapper>
                    <AccordionClickBox>
                        Received Images
                        <Tooltip title='Filter and Sort'>
                            <IconButton onClick={ trigger }>
                            { expand
                                ? <ExpandLessIcon />
                                : <ExpandMoreIcon />
                            }
                            </IconButton>
                        </Tooltip>
                    </AccordionClickBox>

                    <AccordionExpand expand={ expand }>
                        <SortnFilterContainer>
                            <SectionTitle>Filter and Sort</SectionTitle>
                            <StaticPickers controlFunctions={ props.controlFunctions } trigger={ trigger } />
                        </SortnFilterContainer>
                    </AccordionExpand>
                </AccordionWrapper>
            </AccordionContainer>
            
        </MuiPickersUtilsProvider>
    );
}

export default Accordion
