// Reference: https://material-ui.com/components/snackbars/#snackbar
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
    })
);

// the bar showing some warning messages or reminders
export default function OnSuccessSnackbars(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar open={props.open} autoHideDuration={3000} onClose={props.controlFunction}>
                <Alert onClose={props.controlFunction} severity={ props.severity ? props.severity : "success" } >
                    { props.children }
                </Alert>
            </Snackbar>
        </div>
    );
}