import styled from 'styled-components';

export const AccordionContainer = styled.div`
    padding: 0 100px;
    background-color: #f7f7f7;

    @media screen and (max-width: 945px) {
        padding: 10px 15px;
    }
`;

export const AccordionWrapper = styled.div`
    @media screen and (max-width: 945px) {
        padding: 0 55px;
    }

    @media screen and (max-width: 480px) {
        padding: 0;
    }
`;

export const AccordionClickBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 100;

    @media screen and (max-width: 945px) {
        flex-direction: column;
        justify-content: center;
    }
`;

export const AccordionExpand = styled.div`
    max-height: ${ ({ expand }) => expand ? '600px' : '0' };
    display: grid;
    position: absolute;
    padding: 0 15px;
    border-radius: 4px;
    box-shadow: 5px 5px 5px rgba(0,0,0,.2);
    background: #d5d8dc;
    overflow: hidden;
    transition: max-height .3s ease;
    z-index: 100;

    @media screen and (max-width: 945px) {
        width: 100%;
        position: relative;
        grid-template-columns: 1fr;
        box-shadow: none;
        justify-content: center;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    }
`;

export const SectionTitle = styled.h2`
    padding-left: 10px;
    font-size: 18px;

    @media screen and (max-width: 945px) {
        padding: 10px;
        text-align: center;
    }

    @media screen and (max-width: 480px) {
        padding: 10px;
        text-align: center;
    }
`;

export const SortnFilterContainer = styled.div`
    display: grid;
    padding: 15px;

    @media screen and (max-width: 945px) {
        justify-content: center;
    }
`;
