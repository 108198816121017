// Author: Gary
import React from 'react';
import { NavLink } from 'react-router-dom';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import './Navbar.css';

// the upper-most bar showing a menu icon and CIRA's logo
function Navbar(props) {
    return (
        <div className="navbar">
            <div className="navbar-container">
                <div className="menu-bars">
                    <NavLink to="#">
                        <MenuTwoToneIcon onClick={ props.controlFunction } />
                    </NavLink>
                </div>
                <div className="navbar-loco">
                    <NavLink to="/">
                        CIRA
                    </NavLink>
                </div>
            </div>
        </div>
    );
}

export default Navbar
