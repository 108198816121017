import styled from 'styled-components';
//import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

export const TableContainer = styled.div`
    padding: 0 30px;
    align-items: center;
    justify-content: center;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    table-layout: fixed;
    font-size: .9em;
`;

export const TableRow = styled.tr`
    border-bottom: 1px solid #dddddd;
`;

export const TableCol = styled.td`
    padding: 12px 15px;
`;

export const NameEditor = styled.input`
    
`
export const NameEditorWrapper = styled(Tooltip)`
    cursor: pointer;
`