import styled from 'styled-components';

export const CardContainer = styled.div`
    min-height: 250px;
    min-width: 340px;
    height: ${ ({ fullHeight }) => fullHeight ? '100%' : '' } ;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 30px 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
    justify-content: flex-start;
    //align-items: center;
    background-color: #fff;
    transition: all .2s ease-in-out;
    overflow: hidden;
    
    &:hover {
        transform: ${ ({ staticCard }) => staticCard ? 'scale(1)' : 'scale(1.02)' };
        transition: all .2s ease-in-out;
    }
`;

export const SmallCardContainer = styled.div`
    height: ${ ({ fullHeight }) => fullHeight ? '100%' : '' } ;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
    justify-content: flex-start;
    background-color: #fff;
    transition: all .2s ease-in-out;
    overflow: hidden;
    
    &:hover {
        transform: ${ ({ staticCard }) => staticCard ? 'scale(1)' : 'scale(1.02)' };
        transition: all .2s ease-in-out;
    }
`;

export const CardHeading = styled.div`
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
    }
`;

export const CardContent = styled.div`
    display: flex;
    justify-content: ${ ({ leftContent }) => leftContent ? 'left' : 'center' };
`;