// Author: Gary
// url: /about
import React from 'react';
import {
    PageFrame,
    PageTitle,
    PageTitleContent,
    PageHr
} from '../PageTemplate';

// this page is to show the background of CIRA project
// this page may be unnecessary, feel free to delete it
function AboutUsPage() {
    return (
        <PageFrame>
            <PageTitle>
                <PageTitleContent>About Us</PageTitleContent>
                <PageHr />
            </PageTitle>
        </PageFrame>
    );
}

export default AboutUsPage
