import styled from 'styled-components';

export const DatePickerContainer = styled.div`
    width: 350px;
    display: block;
    padding: 10px;

    @media screen and (max-width: 480px) {
        width: 320px;
    }
`;

export const DatePickerWrapper = styled.div`
    display: flex;
`;

export const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: ${ ({ gridGap }) => gridGap ? '1fr 1fr' : '' };
    grid-gap: ${ ({ gridGap }) => gridGap ? gridGap : 0 };

    @media screen and (max-width: 480px) {
        padding-top: 10px;
    }
`;

export const ButtonTitle = styled.div`
    padding-bottom: 5px;

    @media screen and (max-width: 945px) {
        text-align: center;
    }
    
    @media screen and (max-width: 480px) {
        text-align: center;
    }
`;

export const FunctionBarWrapper = styled.div`
    display: flex;
    padding-top: 20px;

    @media screen and (max-width: 480px) {
        display: grid;
    }
`;

export const SortingWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

