// Author: Gary
import React from 'react';
import Image from '../Gallery/Image/Image';
import {
    ModalCover, 
    ModalContainer,
    ModalWrapper,
    ModalInfoBox,
    ModalTitle,
    ModalButtonBox,
    PercentageIndicator,
} from './ModalElements';
import {
    TableContainer,
    TableRow,
    TableCol,
    Table
} from '../../components/Table/TableElements';
import { Button } from '@material-ui/core';
import {CircularProgressWithLabel} from '../Progress/Progress';

// slightly different from the component 'Dialog', it is used to show an enlarged image of a device
const Modal = (props) => {
    const totalPktNum = props.selectedImg.totalPktNum;
    const totalPktReceive = props.selectedImg.totalPktReceive;
    const completion = (totalPktReceive / totalPktNum) * 100;
    let regionEnable = props.selectedImg.regionEnable;
    let discrepancyThreshold = props.selectedImg.discrepancyThreshold;
    let imageType = "Full Image";
    console.log("regionEnable is " + regionEnable);
    console.log("discrepancyThreshold is " + discrepancyThreshold);
    if (regionEnable=="0" && discrepancyThreshold=="0") {
      imageType = "Full Image";
    } else if (regionEnable!="0" && discrepancyThreshold=="0") {
      imageType = "Region Image";
    } else if (regionEnable=="0" && discrepancyThreshold!="0") {
      imageType = "Discrepancy Image, threshold: " + discrepancyThreshold+"%";
    } else {
      imageType = "Region Discrepancy Image, threshold: "+ discrepancyThreshold+"%";
    }


    return (
        <ModalCover showModal={ props.showModal }>
            <ModalContainer>
                <ModalWrapper>
                    <Image src={ props.selectedImg.path } staticImage={ true } />
                    
                    <ModalInfoBox>
                        <ModalTitle>Image Details</ModalTitle>
                        
                            <TableContainer style={ { padding: 1 } }>
                            <Table>
                                <TableRow>
                                    <TableCol colSpan={3}>Photo Type:</TableCol>
                                    <TableCol colSpan={7}>{imageType}</TableCol>
                                </TableRow>
                                <TableRow>
                                    <TableCol colSpan={3}>Take Photo Date:</TableCol>
                                    <TableCol colSpan={3}>{ props.selectedImg.date}</TableCol>

                                    <TableCol colSpan={3}>Image Index:</TableCol>
                                    <TableCol colSpan={1}>{ props.selectedImg.image_index}</TableCol>
                                </TableRow>

                                <TableRow>
                                    
                                    <TableCol colSpan={6}>Packet Received / Total Packet Number:</TableCol>
                                    <TableCol colSpan={2}>{ props.selectedImg.totalPktReceive }/{ props.selectedImg.totalPktNum }</TableCol>
                                    <TableCol colSpan={2} style={{ textAlign: 'center' }}>
                                        <PercentageIndicator>
                                            <CircularProgressWithLabel value={ completion } />
                                        </PercentageIndicator>
                                    </TableCol>
                                </TableRow>
                                <TableRow>
                                    <TableCol colSpan={3}>Quality Index:</TableCol>
                                    <TableCol colSpan={1}>{ props.selectedImg.qualityIndex }</TableCol>
                                    <TableCol colSpan={3}>Last update:</TableCol>
                                    <TableCol colSpan={3}>{ props.selectedImg.receivedDate + " " + props.selectedImg.receivedTime}</TableCol>
                                </TableRow>


                                 <TableRow> 
                                    <TableCol colSpan={3}>Temperature:</TableCol>
                                    <TableCol colSpan={1}>{ props.selectedImg.temp_latest }°C</TableCol>
                                    <TableCol colSpan={1}></TableCol>
                                    <TableCol colSpan={2}>Humidity: </TableCol>
                                    <TableCol colSpan={1}>{ props.selectedImg.humi_latest }%</TableCol>
                                    <TableCol colSpan={1}>(Latest)</TableCol>
                                </TableRow>

                                <TableRow> 
                                    <TableCol colSpan={3}>RSSI:</TableCol>
                                    <TableCol colSpan={2}>{ props.selectedImg.rssi_latest } dBm</TableCol>
                                    <TableCol colSpan={3}>SNR:</TableCol>
                                    <TableCol colSpan={2}>{ props.selectedImg.snr_latest } dB</TableCol>
                                </TableRow>


                               
                            </Table>
                        </TableContainer>



                        <ModalButtonBox>
                            <Button 
                                variant='contained' 
                                color='primary' 
                                onClick={ props.controlFunction.bind(this, {}) }
                            >
                                Close
                            </Button>
                        </ModalButtonBox>
                    </ModalInfoBox>
                </ModalWrapper>
            </ModalContainer>
        </ModalCover>
    );
}

export default Modal
