import styled from 'styled-components';

export const ImageOverlay = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px;
    color: #f1f1f1;
    background: rgba(0,0,0,.5);
    opacity: 0;
    transition: .3s ease;
`;

export const Img = styled.img`
    height: auto;
    width: 100%;
    display: block;
    border-radius: 4px;
    object-fit: cover;
`;

export const ImageFrame = styled.div`
    position: relative;
    box-shadow: 5px 5px 5px rgba(0,0,0,.2);
    transition: .3s ease;

    &:hover ${ ImageOverlay } {
        opacity: 1;
    }

    &:hover {
        transform: ${ ({ staticImage }) => staticImage ? 'scale(1)' : 'scale(1.02)' };
        transition: .3s ease;
        cursor: ${ ({ staticImage }) => staticImage ? 'auto' : 'pointer' };
    }
`;
