// Reference: https://material-ui.com/api/input/#main-content
// Author: Gary
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Calendar as MaterialCalendar } from '@material-ui/pickers';
import { Paper } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import BinarySwitch from '../BinarySwitch/BinarySwitch';
import Button from '@material-ui/core/Button';
import { ButtonsContainer, ButtonTitle, ButtonWrapper, DatePickerContainer, DatePickerWrapper, FunctionBarWrapper, SortingWrapper } from './InputElements';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
    })
);

// Reference: https://material-ui.com/components/selects/
// drop-down menu for inputing data
export default function Select(props) {
    const classes = useStyles();
    const [item, setItem] = React.useState(props.defaultItem);

    const handleChange = (event) => {
        setItem(event.target.value);
        props.controlFunction(event.target.value);
    };

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField
                    select
                    label={props.label}
                    value={item}
                    onChange={handleChange}
                    helperText={props.helperText}
                >
                    {
                        props.items.map(
                            (option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                        )
                    }
                </TextField>
            </div>
        </form>
    );
}

// Reference: https://material-ui-pickers.dev/demo/datepicker#static-mode
// date picker - calendar
export function StaticPickers(props) {
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const [calendarMode, setCalendarMode] = React.useState("start-date");
    const toggle = (mode) => setCalendarMode(mode);

    const confirmFilter = () => {
        props.controlFunctions[1]([startDate.toISOString().substr(0, 10), endDate.toISOString().substr(0, 10)]);
        props.trigger();
    }

    const resetFilter = () => {
        props.controlFunctions[1](false);
        props.trigger();
    }

    function Calendar(props) {
        return (
            <>
                <Paper style={{ overflow: "hidden", width: "100%" }}>
                    <MaterialCalendar date={props.date} onChange={props.onChange} minDate={props.minDate} />
                </Paper>
            </>
        );
    }

    return (
        <DatePickerContainer>
            <ButtonsContainer>
                <ButtonWrapper>
                    <ButtonTitle>From</ButtonTitle>
                    <Button 
                        variant="contained" 
                        color={ calendarMode === 'start-date' ? "primary" : ""} 
                        onClick={ toggle.bind(this, 'start-date') }
                    >
                        { startDate.toISOString().substr(0, 10) }
                    </Button>
                </ButtonWrapper>
                
                <ButtonWrapper>
                    <ButtonTitle>To</ButtonTitle>
                    <Button 
                        variant="contained" 
                        color={ calendarMode === 'end-date' ? "primary" : ""}
                        onClick={ toggle.bind(this, 'end-date') }
                    >
                        { endDate.toISOString().substr(0, 10) }
                    </Button>
                </ButtonWrapper>
            </ButtonsContainer>
            <DatePickerWrapper>
            {
                // calendar for picking the start-date
                calendarMode === 'start-date' && <Calendar date={startDate} onChange={setStartDate} />
            }
            {
                // calendar for picking the end-date, the date cannot be earlier than the start-date
                calendarMode === 'end-date' && <Calendar date={endDate} onChange={setEndDate} minDate={startDate} />
            }
            </DatePickerWrapper>
            
            <FunctionBarWrapper>
                <SortingWrapper>
                    <div style={{ paddingRight: 10 }}>Order: </div>
                    <BinarySwitch label={ ['Descending', 'Ascending'] } controlFunction={ props.controlFunctions[0] }/>
                </SortingWrapper>
                <ButtonWrapper gridGap={ '10px' }>
                    <Button variant="outlined" color="secondary" onClick={ resetFilter }>Reset</Button>
                    <Button variant="outlined" color="primary" onClick={ confirmFilter }>Filter</Button>
                </ButtonWrapper>
            </FunctionBarWrapper>
        </DatePickerContainer>
    );
}