// Author: Gary
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import RouterTwoToneIcon from '@material-ui/icons/RouterTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

// the content shown in the sidebar
export const SidebarFields = [
    {
        title: "Home",
        icon: <HomeTwoToneIcon />,
        link: "/home"
    },
    {
        title: "Dashboard",
        icon: <DashboardTwoToneIcon />,
        link: "/dashboard"
    },
    {
        title: "Devices",
        icon: <RouterTwoToneIcon />,
        link: "/devices"
    },
    {
        title: "Registration",
        icon: <AddCircleTwoToneIcon />,
        link: "/register"
    },
    {
        title: "About Us",
        icon: <InfoTwoToneIcon />,
        link: "/about"
    },
    {
        title: "Settings",
        icon: <SettingsTwoToneIcon />,
        link: "/settings"
    },
    {
        title: "Logout",
        icon: <SettingsTwoToneIcon/>,
        link: "/logout"
    },
];
    