import React from 'react';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { MapWithDraggableMarker } from '../../components/Map/Map';
import { AccessibleTable } from '../../components/Table/Table';
import Row, { ThreeColRow } from '../../components/Row/Row';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '../../components/Input/Input';
import QrReader from 'react-qr-reader';
import { LoadingPage } from '../../components/Progress/Progress';
import {
    TableContainer,
    TableRow,
    TableCol,
    Table
} from '../../components/Table/TableElements';
import axios from '../../components/axios';

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8', 
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <div 
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {
                value === index && (
                    <Box p={3} className={classes.tabpanel}>
                        <Typography>{children}</Typography>
                    </Box>
                )
            }
        </div>
    );
}

const AntTab = withStyles(
    (theme) => ({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            marginRight: theme.spacing(4),
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                color: '#40a9ff',
                opacity: 1,
            },
            '&$selected': {
                color: '#1890ff',
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&:focus': {
                color: '#40a9ff',
            },
        },
        selected: {},
    })
)((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(
    (theme) => ({
        root: {
            flexGrow: 1,
        },
        padding: {
            padding: theme.spacing(3),
        },
        demo1: {
            backgroundColor: '#f7f7f7'
        },
        tabpanel: {
            padding: '40px 0'
        }
    })
);

export const ButtonContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${({column}) => column ? column : '1fr'};
    grid-gap: 10px;
    padding: 10px 0;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

export const QRCodeScannerContainer = styled.div`
    width: 100%;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
`;

export const CardDivider = styled.div`
    display: grid;
    grid-gap: 20px;
`;

export const HintCardContent = styled.div`
    width: 100%;
    text-align: start;
`;

const PrintPageCover = styled.div`
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    position: fixed;
    background-color: antiquewhite;
    z-index: 10000;

    @media print {
        display: grid;
        grid-template-columns: auto auto;
    }
`;

const PrintedItem = styled.div`
    border: 1px dashed black;
`;

const PrintedItemInfoBox = styled.div``;

const Td = styled.td`
padding-right: 10px;
`;


export default function TabbasedSection(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [generateNum, setGenerateNum] = React.useState(1);
    const [rows, setRows] = React.useState([]);
    const [scannedInfo, setScannedInfo] = React.useState({});
    const [currentLocation, setCurrentLocation] = React.useState([]);
    const [loadingPageOpen, setLoadingPageOpen] = React.useState(false);
    const [redirectURL, setRedirectURL] = React.useState('');

    const redirectTo = (url) => {
        setLoadingPageOpen(true);
        setRedirectURL(url);
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
            let curpos = [];
            try {
                curpos = [pos.coords.latitude, pos.coords.longitude];
            } catch(e) {
                console.log([0, 0]);
            }
            setCurrentLocation(curpos);
        });
    }

    const changePosition = (pos) => setCurrentLocation(pos);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSelection = (num) => {
        setGenerateNum(num);
    }

    // the info is in this format: EUI_%_Name
    const qrcodeInfoDecoder = (info, separator) => {
        var patt = new RegExp(/\b\w{16}_%_/);
        if(patt.test(info)) {
            return info.split(separator);
        }
        return [];
    }

    const resetInfo = () => setScannedInfo({});

    const createNumber = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 5,
            label: '5',
        },
        {
            value: 10,
            label: '10',
        },
    ];

    async function generateNewDevices() {
        try {
            const info = await axios.post(
                `/static/generate_devInfo.php`, 
                JSON.stringify({num: generateNum})
            );
            console.log(info.data);
            setRows(info.data);
        } catch (error) {
            console.log('fetch data FAILED.');
            console.log(error);
        }
    };

    function PrintFriendlyPage(props) {
        return (
            <PrintPageCover>
            {
                rows.map((val, key) => {
                    return (
                        <PrintedItem>
                            <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${val.device_eui}_%_${val.device_name}`} alt='' /> 
                            <PrintedItemInfoBox>
                                <table>
                                    <tr style={{ padding: 5}}>
                                        <Td>Device Name: </Td>
                                        <Td>{val.device_name}</Td>
                                    </tr>
                                    <tr style={{ padding: 5}}>
                                        <Td>Device EUI: </Td>
                                        <Td>{val.device_eui}</Td>
                                    </tr>
                                </table>
                            </PrintedItemInfoBox>
                        </PrintedItem>
                    );
                })
            }  
            </PrintPageCover>
        );
    }

    function confirmDeviceRegistration() {
        if (Object.keys(scannedInfo).length < 1) {
            props.controlFunctions[2]("warning", "Device Name and Device EUI cannot be blank.");
        } else { 
            var payload = new FormData();
            payload.append('registration', true);
            payload.append('devEUI', scannedInfo.device_eui);
            payload.append('devName', scannedInfo.device_name);
            try {
                axios.post(
                    `/john/device_registration.php`, payload
                ).then(
                    axios.post(
                        `/static/register_newDevice.php`, 
                        JSON.stringify({
                            device_eui: scannedInfo.device_eui,
                            device_name: scannedInfo.device_name,
                            latitude: currentLocation[0],
                            longitude: currentLocation[1],
                        })
                    ).then(
                        (result) => {
                            if (result.data === 'device not exists') {
                                props.controlFunctions[2]("warning", "Device does not exist.");
                            } else if (result.data.includes("device_id")) {
                                let device_id = parseInt(result.data.substr(10));
                                console.log(`Device Registration Succeed.`);
                                console.log(`->device_eui: ${ scannedInfo.device_eui })`);
                                console.log(`->device_name: ${ scannedInfo.device_name })`);
                                setScannedInfo({});
                                props.controlFunctions[2]("success", "Device Registration Succeed.");
                                redirectTo(`/device/${ device_id }`);
                            }
                        }
                    )
                );
            } catch (error) {
                console.log('fetch data FAILED.');
                console.log(error);
            }
        }
    };

    const changeDeviceName = (key, name) => {
        let temp = rows.slice();
        temp[key]['device_name'] = name;
        setRows(temp);
        console.log(temp);
    }

    return (
        <div className={classes.root}>
            {
                loadingPageOpen && <LoadingPage redirectURL={ redirectURL } />
            }
            
            <div className={classes.demo1}>
                <AntTabs value={value} onChange={handleChange}>
                    <AntTab label="Create Device" />
                    <AntTab label="Register Device" />
                </AntTabs>

                {/** Create Device Tab */}
                <TabPanel value={value} index={0}>
                    <div>
                        <Row grey={ true } columns={ '1fr 3fr' } padding={ '0' } inner={true}>
                            <Card staticCard={true}>
                                <div style={{display: 'block', justifyContent: 'center'}}>
                                    <Select 
                                        items={createNumber} 
                                        defaultItem={createNumber[0].value}
                                        label={"Number of new device(s)"}
                                        controlFunction={handleSelection}
                                    />
                                    <ButtonContainer>
                                        <Button 
                                            variant='contained' 
                                            color='primary'
                                            onClick={generateNewDevices}  
                                        >
                                            Create
                                        </Button>
                                    </ButtonContainer>
                                </div>          
                            </Card>
                            
                            <AccessibleTable rows={rows} controlFunctions={props.controlFunctions} changeDeviceName={changeDeviceName} />
                        </Row>
                    </div>
                </TabPanel>

                {/** Register Device Tab */}
                <TabPanel value={value} index={1}>
                    <div>
                        <ThreeColRow grey={ true } columns={ '1fr 2fr 1fr' } padding={ '0' } inner={true}>
                            <QRCodeScannerContainer>
                                <QrReader
                                    style={{ width: '100%' }}
                                    delay={300} 
                                    onScan={(data) => {
                                        if(data) {
                                            console.log(data);
                                            let info = qrcodeInfoDecoder(data, '_%_');
                                            setScannedInfo({
                                                device_eui: info[0],
                                                device_name: info[1]
                                            });
                                        }
                                    }} 
                                />
                            </QRCodeScannerContainer>
                            <Card staticCard={true} fullHeight={true}>
                                <TableContainer>
                                    <Table style={ { margin: 0 } }>
                                        <TableRow>
                                            <TableCol>Device Name:</TableCol>
                                            <TableCol>{scannedInfo['device_name']}</TableCol>
                                        </TableRow>
                                        <TableRow>
                                            <TableCol>Device EUI:</TableCol>
                                            <TableCol>{scannedInfo['device_eui']}</TableCol>
                                        </TableRow>
                                        <TableRow>
                                            <TableCol>Location:</TableCol>
                                            <TableCol>{currentLocation[0]} {currentLocation[1]}</TableCol>
                                        </TableRow>
                                    </Table>
                                    <ButtonContainer column={'1fr 1fr'}>
                                        <Button 
                                            variant='contained' 
                                            color='primary'
                                            onClick={confirmDeviceRegistration}
                                        >
                                            Register
                                        </Button>
                                        <Button 
                                            variant='contained' 
                                            onClick={resetInfo}  
                                        >
                                            Reset
                                        </Button>
                                    </ButtonContainer>  
                                </TableContainer>        
                            </Card>
                            
                            <MapWithDraggableMarker 
                                sty={{height: 250}}
                                currentLocation={currentLocation}
                                controlFunction={changePosition}
                            />
                        </ThreeColRow>
                    </div>
                </TabPanel>
                <Typography className={classes.padding} />
            </div>

            {/** the printing-friendly page displaying all QR codes of the created devices */}
            <PrintFriendlyPage />
        </div>
    );
}
