import styled, { keyframes } from 'styled-components';

export const ModalCover = styled.div`
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: ${ ({ showModal }) => showModal ? 'flex' : 'none' };
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.4);
    z-index: 10000;
`;

const animationFadeIn = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
    // 0% {opacity: 0}
    // 100% {opacity: 1}
`;

export const ModalContainer = styled.div`
    width: 80%;
    border: 2px solid #888;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2), 0 6px 20px 0 rgba(0,0,0,.19);
    background-color: #fff;
    animation-name: ${animationFadeIn};
    animation-duration: .5s;
    
    @media screen and (max-width: 768px) {
        height: 80%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 15px;
        }

        &::-webkit-scrollbar-thumb {
            border: 1px solid #fff;
            border-radius: 5px;
            background-color: rgba(0,0,0,0.2);
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0,0,0,0.4);
        }
    }
`;

export const ModalWrapper = styled.div`
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`;

export const ModalInfoBox = styled.div`

`;

export const PercentageIndicator = styled.p`
    transition: 0.3s ease-out;
    &:hover {
        transform: scale(1.3);
    }
`;

export const ModalButtonBox = styled.div`
    width: 100%;
    display: flex;

    @media screen and (max-width: 768px) {
        justify-content: center;
        button {
            width: 100%
        }
    }
`;

export const ModalRow = styled.p`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: grid;
        justify-content: center;
    }
`;

export const ModalTitle = styled.p`
    font-size: 1.5rem;
    font-weight: bold;

    @media screen and (max-width: 768px) {
        display: flex;
        justify-content: center;
    }
`;

export const SettingIcon = styled.div`
    grid-column-start: -1;
`;
