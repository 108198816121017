// Author: Gary
// In my point of view, this part is the most challenging part. Changes on this code are not recommended.
// If you want to change the code anyway, I would suggest you rewrite this part instead.
import React, { useState, useEffect } from 'react';
import BinarySwitch from '../BinarySwitch/BinarySwitch';
import {
    CanvasContainer,
    FunctionBarItem,
    FunctionBarContainer,
    RegionPickerCanvas,
    RegionPickerContainer,
    RegionPickerWrapper,
    MainCanvas,
    ValueIndicator,
    ConfirmButtonBar
} from './RegionPickerElements'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from '../../components/axios';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// the canvas for selecting a targeted region
export default function RegionPicker(props) {
    const [grid, setGrid] = useState(true);
    const [gridCanvas, setGridCanvas] = useState(null);
    const [region, setRegion] = useState([[0, 0], [19, 14]]);
    const [buttonDisabled, setButtonDisabled] = useState({
        width: [false, true],
        height: [false, true]
    });
    const [selectedValue, setSelectedValue] = useState(false);
    const [showRegion, setShowRegion] = useState(true);
    const [discrepancyThreshold, setDiscrepancyThreshold] = useState('20');

    const [regionVisable, setRegionVisable] = useState(true);
    const [discrepancyVisable, setDiscrepancyVisable] = useState(false);

    function disOnClickEvent(){
        if (selectedValue){
            setSelectedValue(false);
            setRegionVisable(true);
            console.log("Region is Visable");          
        }          
        else{
            setSelectedValue(true);
            setRegionVisable(false);
            console.log("Region not Visable");
        }
    }

    useEffect(() => {
        console.log(selectedValue);
    }, [selectedValue])

    const confirmRegion = () => {
        setDiscrepancyThreshold();
        let payload = new FormData();
        payload.append('region', showRegion);
        payload.append('devEUI', props.deviceEUI);
        payload.append('p1x', region[0][0]);
        payload.append('p1y', region[0][1]);
        payload.append('p2x', region[1][0]);
        payload.append('p2y', region[1][1]);
        payload.append('discrepancy', selectedValue);
        payload.append('discrepancyThreshold', discrepancyThreshold);
        //axios.post(`/john/region_selection.php`, payload);

        axios({
            method: "post",
            url: "/john/region_selection.php",
            timeout: 3000, // Wait for 5 seconds
            data: payload,
            headers: { "Content-Type": "multipart/form-data" },
          })
            .then(function (response) {
              //handle success
              console.log(response);
            })
            .catch(function (response) {
              //handle error
              console.log(response);
            });

        props.controlFunctions[0]();
        props.controlFunctions[1]();
        console.log(`confirm region (EUI: ${ props.deviceEUI }) ${ region } ` );
        for (var value of payload.values())
            console.log(value);
    }

    const changeButtonDisable = (width, height) => {
        setButtonDisabled({
            width: width,
            height: height
        });
    }

        const toggleGrid = () => {
            if (showRegion) {
                gridCanvas.style.visibility = 'hidden';
                setShowRegion(false);
                setDiscrepancyVisable(true);
                console.log("Discrepancy is Visable");
            } else {
                gridCanvas.style.visibility = 'visible';
                setShowRegion(true);
                setDiscrepancyVisable(false);
                console.log("Discrepancy not Visable");
            }
        }
        useEffect(() => {
            console.log(showRegion);
        }, [showRegion])


    useEffect(() => {
        if (props.imagepath !== '') {
            var mouseDown = false;
            var lastMouseX = 0;
            var lastMouseY = 0;
            
            var width = 1;
            var height = 1;

            var imageCanvas = document.getElementById('image-canvas');
            var imageCanvasContext = imageCanvas.getContext('2d');

            var gridCanvas = document.getElementById('grid-canvas');
            var gridCanvasContext = gridCanvas.getContext('2d');
            
            var pickerCanvas = document.getElementById('picker-canvas');
            var pickerCanvasContext = pickerCanvas.getContext('2d');

            var widthIndicator = document.getElementById('width-indicator');
            var HeightIndicator = document.getElementById('height-indicator');
            widthIndicator.innerHTML = width;
            HeightIndicator.innerHTML = height;

            var widthButton = {
                increase: document.getElementById('width-control-increase'),
                decrease: document.getElementById('width-control-decrease')
            }

            var heightButton = {
                increase: document.getElementById('height-control-increase'),
                decrease: document.getElementById('height-control-decrease')
            }

            const changeRegion = () => {
                let p1x = lastMouseX / 16;
                let p1y = lastMouseY / 16;
                let p2x = (p1x + 5 * width - 1 > 19) ? 19 : (p1x + 5 * width - 1);
                let p2y = (p1y + 5 * height - 1 > 14) ? 14 : (p1y + 5 * height - 1);

                let startpoint = [p1x, p1y];
                let endpoint = [p2x, p2y];

                setRegion([startpoint, endpoint]);
                console.log(`Start Point: ${ startpoint }`);
                console.log(`End Point: ${ endpoint }`);
            }

            const pickerResize = () => {
                pickerCanvasContext.clearRect(0, 0, imageCanvas.width, imageCanvas.height);
                pickerCanvasContext.strokeRect(
                    lastMouseX,
                    lastMouseY,
                    (image.width / 20) * 5 * width,
                    (image.height / 15) * 5 * height
                );
                widthIndicator.innerHTML = width;
                HeightIndicator.innerHTML = height;
                
                let widthState = [];
                let heightState = [];
                if (width === 1) {
                    widthState = [false, true]
                }

                if (width === 4) {
                    widthState = [true, false]
                }

                if (height === 1) {
                    heightState = [false, true]
                }

                if (height === 3) {
                    heightState = [true, false]
                }
                changeButtonDisable(widthState, heightState);
                changeRegion();
            }
            
            var image = new Image();
            image.src = `https://api.ciratest1.hkrnd.com/static/get_pic.php?path=${props.imagepath}`+"complete.jpg";
            image.onload = function() {
                imageCanvas.width = this.width;
                imageCanvas.height = this.height;
                gridCanvas.width = this.width;
                gridCanvas.height = this.height;
                pickerCanvas.width = this.width;
                pickerCanvas.height = this.height;

                setGridCanvas(gridCanvas);
                imageCanvasContext.drawImage(this, 0, 0);
                
                for (let x = 0; x <= this.width; x += this.width / 20) {
                    gridCanvasContext.moveTo(x, 0)
                    gridCanvasContext.lineTo(x, this.height)
                }

                for (let y = 0; y <= this.height; y += this.height / 15) {
                    gridCanvasContext.moveTo(0, y)
                    gridCanvasContext.lineTo(this.width, y)
                }

                gridCanvasContext.globalAlpha = 0.5;
                gridCanvasContext.strokeStyle = '#f7f7f7';
                gridCanvasContext.stroke();
            }

            // mousedown
            pickerCanvas.onmousedown = function() {
                mouseDown = true;
            }

            pickerCanvas.ontouchstart = function() {
                mouseDown = true;
                console.log('touch-event: mousedown');
            }

            // buttons
            widthButton.increase.onclick = function() {
                if (width < 4) {
                    width += 1;
                }
                pickerResize();
            }

            widthButton.decrease.onclick = function() {
                if (width > 1) {
                    width -= 1;
                }
                pickerResize();
            }

            heightButton.increase.onclick = function() {
                if (height < 3) {
                    height += 1;
                }
                pickerResize();
            }

            heightButton.decrease.onclick = function() {
                if (height > 1) {
                    height -= 1;
                }
                pickerResize();
            }

            //mousemove
            pickerCanvas.onmousemove = function(event) {
                pickerCanvasContext.strokeStyle = 'yellow';
                pickerCanvasContext.lineWidth = 2;

                if (mouseDown) {
                    lastMouseX = Math.floor(event.offsetX / (image.width / 20)) * (image.width / 20) - ((image.width / 10) * width) ;
                    lastMouseY = Math.floor(event.offsetY / (image.height / 15)) * (image.height / 15) - ((image.height / 7.5) * height);

                    if (lastMouseX > image.width - (image.width / 20) * 5 * width) {
                        lastMouseX = image.width - (image.width / 20) * 5 * width;
                    }

                    if (lastMouseX < 0) {
                        lastMouseX = 0;
                    }

                    if (lastMouseY > image.height - (image.height / 15) * 5 * height) {
                        lastMouseY = image.height - (image.height / 15) * 5 * height;
                    }

                    if (lastMouseY < 0) {
                        lastMouseY = 0;
                    }

                    pickerCanvasContext.clearRect(0, 0, imageCanvas.width, imageCanvas.height);
                    pickerCanvasContext.strokeRect(
                        lastMouseX,
                        lastMouseY,
                        (image.width / 20) * 5 * width,
                        (image.height / 15) * 5 * height
                    );
                }
            }

            pickerCanvas.ontouchmove = function(event) {
                pickerCanvasContext.strokeStyle = 'yellow';
                pickerCanvasContext.lineWidth = 2;

                if (mouseDown) {
                    let rect = event.targetTouches[0].target.getBoundingClientRect()
                    let xx = event.targetTouches[0].clientX - rect.left;
                    let yy = event.targetTouches[0].clientY - rect.top;
                    console.log()
                    lastMouseX = Math.floor(xx / (image.width / 20)) * (image.width / 20) - ((image.width / 10) * width) ;
                    lastMouseY = Math.floor(yy / (image.height / 15)) * (image.height / 15) - ((image.height / 7.5) * height);

                    if (lastMouseX > image.width - (image.width / 20) * 5 * width) {
                        lastMouseX = image.width - (image.width / 20) * 5 * width;
                    }

                    if (lastMouseX < 0) {
                        lastMouseX = 0;
                    }

                    if (lastMouseY > image.height - (image.height / 15) * 5 * height) {
                        lastMouseY = image.height - (image.height / 15) * 5 * height;
                    }

                    if (lastMouseY < 0) {
                        lastMouseY = 0;
                    }

                    pickerCanvasContext.clearRect(0, 0, imageCanvas.width, imageCanvas.height);
                    pickerCanvasContext.strokeRect(
                        lastMouseX,
                        lastMouseY,
                        (image.width / 20) * 5 * width,
                        (image.height / 15) * 5 * height
                    );
                    console.log('touch-event: mousemove');
                }
            }

            //mouseup
            pickerCanvas.onmouseup = function() {
                mouseDown = false;
                changeRegion();
            }

            pickerCanvas.ontouchend = function() {
                mouseDown = false;
                changeRegion();
                console.log('touch-event: mouseup');
            }
        }
    }, [props.imagepath]);

    return (
        <>
            {
                props.imagepath !== '' && (
                    <RegionPickerContainer>
                        <RegionPickerWrapper>
                            <CanvasContainer>
                                <MainCanvas id='image-canvas'/>
                                <RegionPickerCanvas id='grid-canvas' />
                                <RegionPickerCanvas id='picker-canvas' />
                            </CanvasContainer>
                            <FunctionBarContainer>
                                {regionVisable && (
                                <FunctionBarItem>
                                    <div> Region:
                                    <BinarySwitch id='regionChange' label={ ['', ''] } pass={showRegion} controlFunction={ toggleGrid }>
                                    </BinarySwitch>                                    
                                    </div>
                                </FunctionBarItem>
                                )}
                                
                                <FunctionBarItem>
                                    <div>Width:</div>
                                    <div style={{display: 'flex'}}>
                                        <Tooltip title="Increase">
                                            <IconButton id='width-control-increase' disabled={ buttonDisabled.width[0] }>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <ValueIndicator id='width-indicator' />
                                        <Tooltip title="Decrease">
                                            <IconButton id='width-control-decrease' disabled={ buttonDisabled.width[1] }>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </FunctionBarItem>

                                <FunctionBarItem>
                                    <div>Height:</div> 
                                    <div style={{display: 'flex'}}>
                                        <Tooltip title="Increase">
                                            <IconButton id='height-control-increase' disabled={ buttonDisabled.height[0] }>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <ValueIndicator id='height-indicator' />
                                        <Tooltip title="Decrease">
                                            <IconButton id='height-control-decrease' disabled={ buttonDisabled.height[1] }>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </FunctionBarItem>


                                {discrepancyVisable && (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Discrepancy detection:</FormLabel>
                                    <RadioGroup row aria-label="position" name="position" defaultValue="F" onChange={disOnClickEvent}>
                                    <FormControlLabel value="F" id="F" control={<Radio color="primary" />} label="False"/>
                                    <FormControlLabel value="T" id="T" control={<Radio color="primary" />} label="True"/>   
                                    </RadioGroup>
                                    <TextField id="discrepancyThreshold" label="Discrepancy Threshold (1,2...9)"  variant="standard" onChange={event => setDiscrepancyThreshold(event.target.value*10)}/>   
                                </FormControl>
                                )}

                                <ConfirmButtonBar>
                                    <Button variant="contained" color="primary" onClick={ confirmRegion }>Confirm</Button>
                                </ConfirmButtonBar>

                            
                            </FunctionBarContainer>
                        </RegionPickerWrapper>
                    </RegionPickerContainer>
                )
            }
        </>
    )
}