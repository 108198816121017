// Author: Gary
// Layout component
import React from 'react';
import { 
    RowContainer,
    RowWrapper,
    ThreeColRowWrapper
} from './RowElements';

const Row = (props) => {
    return (
        <RowContainer grey={ props.grey } padding={ props.padding }>
            <RowWrapper columns={ props.columns } padding={ props.padding } inner={ props.inner }>
                { props.children }
            </RowWrapper>
        </RowContainer>
    );
}

export default Row

export const ThreeColRow = (props) => {
    return (
        <RowContainer grey={ props.grey } padding={ props.padding }>
            <ThreeColRowWrapper columns={ props.columns } padding={ props.padding } inner={ props.inner }>
                { props.children }
            </ThreeColRowWrapper>
        </RowContainer>
    );
}
