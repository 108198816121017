import React, { useState } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Login from './pages/Login/login';
import Logout from './pages/Login/logout';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import DashboardPage from './pages/Dashboard/DashboardPage';
import Device from './pages/Device/Device';
import DeviceMainPage from './pages/Device/DeviceMain';
import './App.css';
import Registeration from './pages/Registeration/Registeration';
import OnSuccessSnackbars from './components/Snackbar/Snackbar';
import AboutUsPage from './pages/About/About';
//import Footer from './components/Footer/Footer';
import useToken from './useToken';

function App() {
  const {token, setToken} = useToken();
  const [sidebarActive, setActive] = useState(false);
  const showSidebar = () => setActive(!sidebarActive);

  //SnackBar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const toggleSnackBar = (type, msg) => {
      if (type && msg) {
          setSnackbarType(type);
          setSnackbarMsg(msg);
      }
      setSnackbarOpen(!snackbarOpen);
  };

  // go to login page if not token
  if (!token){
    return <Login setToken={setToken} />
  }

  return (
    <div className="App">
      <Router>
        <Navbar controlFunction={ showSidebar } />
        <Sidebar active={ sidebarActive } controlFunction={ showSidebar } />
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/login" default children={ <Login /> } /> 
          <Route path="/logout" children={ <Logout /> } />       
          <Route path="/dashboard" children={ <DashboardPage /> } />
          <Route path="/home" children={ <Home /> } />
          <Route path="/devices" children={ <DeviceMainPage /> } />
          <Route path="/device/:deviceId" children={ <Device /> } />
          <Route path="/register" children={ <Registeration controlFunction={ toggleSnackBar } /> } />
          <Route path="/about" children={ <AboutUsPage /> } />
        </Switch>
      </Router>
      
      <OnSuccessSnackbars open={ snackbarOpen } controlFunction={ toggleSnackBar } severity={ snackbarType }>
        { snackbarMsg }
      </OnSuccessSnackbars>
    </div>
  );
}

export default App