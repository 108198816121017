// Author: Gary
import React from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarFields } from './SidebarFields';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import './Sidebar.css';

// the side menu
function Sidebar(prop) {
    return (
        <>
            <nav className={ prop.active ? "sidebar active" : "sidebar" }>
                <ul className="sidebar-list"> 
                    <li className="sidebar-toggle">
                        <NavLink to="#">
                            <CloseTwoToneIcon onClick={ prop.controlFunction } />
                        </NavLink>
                    </li>
                    {   
                        // refer to SidebarFields.js
                        SidebarFields.map((val, key) => {
                            return (
                                <li key={ key } className="sidebar-item"> 
                                    <NavLink 
                                        to={ val.link } 
                                        activeClassName="sidebar-item active" 
                                        className="sidebar-item-link"
                                        onClick={ prop.controlFunction }
                                    >
                                        <div className="sidebar-item-icon">{ val.icon }</div>
                                        <div className="sidebar-item-title">{ val.title }</div>
                                    </NavLink>          
                                </li>
                            );
                        })
                    } 
                </ul>
            </nav>
        </>
    );
}

export default Sidebar
