import styled from 'styled-components';

export const LoadingIcon = styled.div`
    height: 100px;
    width: 100px;
`;

export const CompletionIcon = styled.div`
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: white;
`;