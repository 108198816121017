// Author: Gary
import React from 'react';
import PropTypes from 'prop-types';
import { ChipContainer, GalleryContainer, Message, MessageContainer } from './GalleryElements';
import Image from './Image/Image';
import FilterListIcon from '@material-ui/icons/FilterList';
import OutlinedChips from '../Chip/Chip';

const Gallery = (props) => {
    const trimDate = (date, start, end, symbol) => {
        date = date.substring(start, end);
        return parseInt(date.replaceAll(symbol, ''));
    }

    // function for sorting (ascending / descending)
    const compareDate = (d1, d2, order) => {
        var date = trimDate(d1, 0, 10, '-') - trimDate(d2, 0, 10, '-');
        var time = trimDate(d1, 11, 19, ':') - trimDate(d2, 11, 19, ':');
        var result = false;

        if (date > 0) {
            result = true;
        } 
        // else if (date === 0 && time > 0) {
        //     result = true;
        // }
        
        if (order) {
            return !result;
        }
        return result;
    };

    const compareImageID = (i1, i2, order) => {
        var result = i1 - i2;
        if (order) {
            return result<=0;
        }
        return result>=0;
    }

    // function for filtering (by date only, you may also think about other ways)
    // hidden surprise
    // this filtering function has a bug, can you realize it and fix it ? haha by Gary
    const filterInDate = (d1, filteringCriteria, filterNow) => {
        if (!filterNow) {
            return true;
        }
        var startDate = trimDate(filteringCriteria[0], 0, 10, '-');
        var endDate = trimDate(filteringCriteria[1], 0, 10, '-');
        var date = trimDate(d1, 0, 10, '-');
        return (date - startDate >= 0) && (date - endDate <= 0);
    }

    return (
        <>
            {
                // first, check whether this device has at least one image
                props.imageSource.length > 0 && (

                    // filter by date
                    <>
                        {/** this is the 'chip' bar showing the results */}
                        <ChipContainer>
                            <OutlinedChips 
                                icon={ <FilterListIcon /> }
                                label={`${
                                    props.imageSource
                                        .filter(a => filterInDate(a.date, props.filteringCriteria, props.filteringCriteria))
                                        .sort((a, b) => (compareDate(a.date, b.date, props.ascending) ? 1 : -1))
                                        .sort((a, b) => (compareImageID(a.image_index, b.image_index, props.ascending) ? 1 : -1))
                                        .length
                                    } matched result(s)
                                `}
                                controlFunction={ props.resetFiltering }
                            />
                        </ChipContainer>
                        
                        {/** if there are matched results, show them */}
                        <GalleryContainer>
                        {
                            props.imageSource
                            .filter(a => filterInDate(a.date, props.filteringCriteria, props.filteringCriteria))
                            .sort((a, b) => (compareImageID(a.image_index, b.image_index, props.ascending) ? 1 : -1))
                            .sort((a, b) => (compareDate(a.date, b.date, props.ascending) ? 1 : -1))
                            
                            
                            .map((val, key) => {
                                return (
                                    <Image
                                        src={ val.path } 
                                        overlay={ val.date }
                                        controlFunction={ props.controlFunction.bind(this, val) }
                                        alt={ 'Received Image' }
                                    />
                                );
                            })
                        }
                        </GalleryContainer>
                    </> 
                )
            } 
            {
                // no images are found in this device
                !props.imageSource.length && (
                    <MessageContainer>
                        <Message>No Images</Message>
                    </MessageContainer>
                )
            }
        </>
    );
}

Gallery.prototype = {
    imageSource: PropTypes.array.isRequired
}

export default Gallery
