// Reference: https://material-ui.com/components/menus/
import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

// drop-down menu
export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onClick={handleClick}>
        { props.children }
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{zIndex: 10001}}
      >
      {
        props.menuList.map((val, key) => {
          return (
            <MenuItem disabled={!val.display} onClick={ () => 
              { 
                handleClose(); 
                val.controlFunction(5);
              }
            }>
              <ListItemIcon>{ val.icon }</ListItemIcon>
              <Typography variant="inherit">{ val.item }</Typography>
            </MenuItem>
          );
        })
      }
      </Menu>
    </div>
  );
}
