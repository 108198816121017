// Reference: https://react-leaflet.js.org/docs/example-popup-marker/
// Author: Gary
import { React, useRef, useEffect, useState, useMemo } from 'react';
import axios from '../axios';
import { 
    MapContainer,
    useMap,
    TileLayer, 
    Marker, 
    Popup 
} from 'react-leaflet';
import './Map.css';

const Map = (props) => {
    const position = [22.372092, 114.108658];
    const ZOOM_LEVEL = 15;
    const mapRef = useRef();
    const [devicesInfo, setDevicesInfo] = useState([]);
    const [centerPosition, setCenterPosition] = useState(position);

    useEffect(() => {
        async function fetchData() {
            try {
                const info = await axios.get(props.endpoint);
                console.log(info.data);
                if(info.data.length === 1) {
                    let latitude = info.data[0].latitude;
                    let longitude = info.data[0].longitude;
                    setCenterPosition([latitude, longitude]);
                }
                setDevicesInfo(info.data); 
            } catch (error) {
                console.log('fetch data FAILED.');
            }
        }
        fetchData();
    }, [props.endpoint]);

    function FlyToCenterPosition() {
        const map = useMap();
        map.flyTo(centerPosition, 15)
        return null;
    }

    return (
        <MapContainer style={ props.sty }
            center={ centerPosition } 
            zoom={ ZOOM_LEVEL } 
            scrollWheelZoom={ true } 
            ref={ mapRef }
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                centerPosition && <FlyToCenterPosition />
            }
            {
                devicesInfo.map(obj => {
                    return (
                        <Marker position={ [obj.latitude, obj.longitude] }>
                        {
                            obj.device_id && 
                                <Popup>
                                    <a href={ `/device/${obj.device_id }` }>{ obj.device_name }</a>
                                </Popup>
                        }
                        </Marker>
                    );
                })
            }
        </MapContainer>
    );
}

export default Map

// as its name suggests, the marker is draggable 
// used in device registration, users can adjust the location of a device
export const MapWithDraggableMarker = (props) => {
    const position = props.currentLocation;
    const [curpos, setPosition] = useState(position);
    const ZOOM_LEVEL = 15;
    const mapRef = useRef();

    const DraggableMarker = () => {
        const markerRef = useRef();
        const eventHandlers = useMemo(() => ({
            dragend() {
                const marker = markerRef.current;
                if (marker !== null) {
                    let pos = [marker.getLatLng().lat, marker.getLatLng().lng];
                    setPosition(pos);
                    props.controlFunction(pos);
                }
            },
        }), []);

        return (
            <Marker 
                draggable={ true }
                eventHandlers={ eventHandlers }
                position={ curpos }
                ref={ markerRef }
            >
                <Popup>{curpos}</Popup>
            </Marker>
        );
    };

    return (
        <MapContainer style={ props.sty }
            center={ position } 
            zoom={ ZOOM_LEVEL } 
            scrollWheelZoom={ true } 
            ref={ mapRef }
        >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <DraggableMarker />
        </MapContainer>
    );
}
