import styled from 'styled-components';

export const RegionPickerContainer = styled.div`
    height: 100%;
    width: 100%;
`;

export const RegionPickerImage = styled.img`

`;

export const RegionPickerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    }
`;

export const CanvasContainer = styled.div`
    min-height: 240px;
    min-width: 320px;
    position: relative;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
        margin: auto;
    }
`;

export const MainCanvas = styled.canvas`
    position: absolute;
    box-shadow: 2px 2px 5px rgba(0,0,0,.2);
    cursor: all-scroll;
`;

export const RegionPickerCanvas = styled.canvas`
    position: absolute;
`;

export const FunctionBarContainer = styled.div`
    @media screen and (max-width: 480px) {
        padding: 10px 0;
    }
`;

export const FunctionBarItem = styled.div`
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
`;

export const ValueIndicator = styled.div`
    margin: auto 10px;
`;

export const ConfirmButtonBar = styled.div`
    display: grid;
`;