import styled from 'styled-components';

export const GalleryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px;
    padding: 20px 100px 50px;
    background-color: #f7f7f7;

    @media screen and (max-width: 945px) {
        grid-template-columns: repeat(2, 1fr);
        padding: 40px 70px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
        padding: 40px 15px;
    }
`;

export const MessageContainer = styled.div`
    padding: 50px 100px;
    background-color: #f7f7f7;

    @media screen and (max-width: 945px) {
        padding: 70px;
        justify-content: center;
    }

    @media screen and (max-width: 945px) {
        padding: 0 15px 70px;
        justify-content: center;
    }
`;

export const Message = styled.h2`
    @media screen and (max-width: 945px) {
        text-align: center;
    }
`;

export const ChipContainer = styled.div`
    padding: 10px 100px;
    background-color: #f7f7f7;

    @media screen and (max-width: 945px) {
        display: flex;
        padding: 0;
        justify-content: center;
    }
`;
