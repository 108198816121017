import CustomPaginationActionsTable from '../../components/Table/Table';
import { React, useState, useEffect } from 'react';
import Row from '../../components/Row/Row';
import {
    PageFrame,
    PageTitle,
    PageHr
} from '../PageTemplate';
import axios from '../../components/axios';
import test from '../Login/login.js';
//const {deviceNumber} = require('../Login/login.js');

const DeviceMainPage = () => {
    const [devicesInfo, setDevicesInfo] = useState([]);
    
    useEffect(() => {
        // get user devices info
        async function fetchData() {
            try {
                const info = await axios.get('/static/getDeviceInfo.php');
                console.log(info);
                let len = info.data.length;
                let device = new Array(len);
                for (let i = 0; i<len; i++){
                    device[i] = info.data[i];
                }
                // check is it user devices
                let userDeviceIdCSV = localStorage.getItem('device');
                let count = localStorage.getItem('deviceNumber');
                let userDeviceId = userDeviceIdCSV.split(',');
                let userIdCSV = localStorage.getItem('userId');
                let userId = userIdCSV.split('"');
                userId = userId[1];

                let userDevice = new Array(count);
                let j = 0;
                for (let i = 0; i<count; i++){
                    let deviceId = userDeviceId[i];
                    try{
                        await axios.post(
                            '/static/checkUserDevice.php', JSON.stringify({userId , deviceId})
                        ).then(
                            result => {
                                if (result.data[0].check == "Yes"){
                                    userDevice[j] = device[deviceId-1];  
                                    j++;
                                }
                            }
                        )
                    } catch (error){
                        console.log(error);
                    }                  
                }

                setDevicesInfo(userDevice);
                
                
            } catch (error) {
                console.log('fetch data FAILED.');
            }
        }
        fetchData();
    }, []);

    return (
        <PageFrame>
            <PageTitle>
                Devices
                <PageHr />
            </PageTitle>
            <Row grey={ true } columns={ '1fr' }>
                <CustomPaginationActionsTable rows={ devicesInfo } />
            </Row>      
        </PageFrame>
    );
}

export default DeviceMainPage
