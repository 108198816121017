// Author: Gary
import React from 'react';
import {
    ImageFrame,
    ImageOverlay,
    Img
} from './ImageElements';

// to be mainly used in Gallery
const Image = (props) => {
    return (
        <ImageFrame onClick={ props.controlFunction } staticImage={ props.staticImage } >
            <Img src={ props.src } alt={ props.alt } />
            {
                props.overlay && (
                    <ImageOverlay>
                        { props.overlay }
                    </ImageOverlay>
                )
            }
        </ImageFrame>
    );
}

export default Image
