// Reference: https://material-ui.com/components/tables/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import GetAppIcon from '@material-ui/icons/GetApp';
import { NameEditorWrapper } from './TableElements';
import { RenameDialog } from '../Dialog/Dialog';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    table: {
        minWidth: 650,
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
        <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
    
    cell: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

// Reference: https://material-ui.com/components/tables/#custom-pagination-options
export default function CustomPaginationActionsTable(props) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const rows = props.rows.sort((a, b) => (a.device_name < b.device_name ? -1 : 1));
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const toDeviceInfoPage = (deviceId) => {
        window.location.href = `/device/${ deviceId }`;
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row">
                            Device Name
                        </TableCell>
                        <TableCell style={{ width: 160, fontWeight: 'bold' }} align="right">
                            Device EUI
                        </TableCell>
                        <TableCell style={{ width: 160, fontWeight: 'bold' }} align="right">
                            Register Date
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row) => (
                        <TableRow 
                            hover 
                            role="checkbox" 
                            tabIndex={-1} 
                            key={row.name} 
                            onClick={toDeviceInfoPage.bind(this, row.device_id)}
                            className={classes.cell}
                        >
                            <TableCell component="th" scope="row">
                                {row.device_name}
                            </TableCell>
                            <TableCell style={{ width: 260 }} align="right">
                                {row.device_eui}
                            </TableCell>
                            <TableCell style={{ width: 260 }} align="right">
                                {row.register_date} 
                            </TableCell>
                        </TableRow>
                    ))}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={props.rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

// Reference: https://material-ui.com/components/tables/#accessibility
export function AccessibleTable(props) {
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const showRenameDialog = () => setRenameDialogOpen(!renameDialogOpen);

    const [targetDeviceInfo, setTargetDeviceInfo] = useState({});
    const getDeviceInfo = (info) => {
        showRenameDialog();
        setTargetDeviceInfo(info);
    }

    const classes = useStyles1();
    const controlFunction = (info) => {
        props.controlFunctions[0]();
        props.controlFunctions[1](info);
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="caption table">
                <caption>Press CREATE to generate new devices. You may Click the device name to rename the device.</caption>
                <TableHead>
                <TableRow>
                    <TableCell>Device EUI</TableCell>
                    <TableCell>Device AppKey</TableCell>
                    <TableCell align="center">Device Name</TableCell>
                    <TableCell align="center">Create Date</TableCell>
                    <TableCell align="center">Get QR Code</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.rows.map((row, key) => (
                    <TableRow key={row.device_eui}>
                    <TableCell component="th" scope="row">
                        {row.device_eui}
                    </TableCell>
                    <TableCell>{row.device_appKey}</TableCell>
                    <TableCell align="center">
                        <NameEditorWrapper 
                            title="Click to rename"
                            onClick={ getDeviceInfo.bind(
                                this, {
                                    device_eui: row.device_eui,
                                    key: key
                                }) 
                            }
                        >
                            <div>{row.device_name}</div>
                        </NameEditorWrapper>    
                    </TableCell>
                    <TableCell align="center">{row.create_date}</TableCell>
                    <TableCell align="center">
                    
                        <IconButton 
                            style={{padding: 3}} 
                            onClick={
                                controlFunction.bind(this, {
                                    device_eui: row.device_eui,
                                    device_appKey: row.device_appKey,
                                    device_name: row.device_name
                                })
                            }
                        >
                            <GetAppIcon />
                        </IconButton>
                    
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            <RenameDialog 
                dialogOpen={ renameDialogOpen }
                controlFunctions={ [showRenameDialog, props.changeDeviceName] }
                deviceInfo={targetDeviceInfo} 
            />
        </TableContainer>
    );
}

CustomPaginationActionsTable.propTypes = {
    rows: PropTypes.array.isRequired
};