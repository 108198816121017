import styled from 'styled-components';

export const RowContainer = styled.div`
    padding: ${ ({ padding }) => padding ? padding : '50px' };
    justify-content: center;
    align-items: center;
    background-color: ${ ({ grey }) => grey ? '#f7f7f7' : '#fff' };

    @media screen and (max-width: 480px) {
        padding: 0px;
    }
`;

export const RowWrapper = styled.div`
    display: grid;
    grid-gap: 50px;
    grid-template-columns: ${ ({ columns }) => columns };
    margin: 0 auto;
    padding: ${ ({ padding }) => padding ? padding : '0 50px' };
    align-items: start;

    @media screen and (max-width: 945px) {
        grid-template-columns: 1fr;
        padding: ${ ({ inner }) => inner ? '0' : '0 20px' };
    }

    @media screen and (max-width: 480px) {
        padding: ${ ({ inner }) => inner ? '0' : '15px' };
    }
`;

export const ThreeColRowWrapper = styled.div`
    display: grid;
    grid-gap: 50px;
    grid-template-columns: ${ ({ columns }) => columns ? columns : '1fr 1fr 1fr' };
    margin: 0 auto;
    padding: ${ ({ padding }) => padding ? padding : '0 50px' };
    align-items: start;

    @media screen and (max-width: 1320px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 945px) {
        grid-template-columns: 1fr;
        padding: ${ ({ inner }) => inner ? '0' : '0 20px' };
    }

    @media screen and (max-width: 480px) {
        padding: ${ ({ inner }) => inner ? '0' : '15px' };
    }
`;
