// Author: Gary
import React from 'react';
import {
    CardContainer, 
    CardHeading,
    CardContent,
    SmallCardContainer
} from './CardElements';

const Card = (props) => {
    return (
        <>
            <CardContainer staticCard={ props.staticCard } fullHeight={ props.fullHeight }>
                {
                    props.heading && 
                    <CardHeading>{ props.heading }</CardHeading>
                }
                <CardContent leftContent={ props.leftContent }>{ props.children }</CardContent>
            </CardContainer>
        </>
    )
}

export default Card;

export const SmallCard = (props) => {
    return (
        <SmallCardContainer>
            { props.children }
        </SmallCardContainer>
    );
}
