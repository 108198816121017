// Reference: https://material-ui.com/components/switches/
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function BinarySwitch(props) {

    const handleChange = (event) => {
        props.controlFunction();
    };
    
    return (
        <FormControlLabel
            label={ props.pass ? props.label[0] : props.label[1] }
            labelPlacement={ props.placement ? props.placement : 'end' }
            control={
                <Switch
                    checked={ props.pass }
                    onChange={ handleChange }
                    name="checkedB"
                    color="primary"
                />
            }
        />
    );
}