import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

export const InfoCardsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    padding: 50px 100px 0;

    @media screen and (max-width: 1320px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }

    @media screen and (max-width: 945px) {
        padding: 40px 70px 0;
    }

    @media screen and (max-width: 480px) {
        grid-gap: 20px;
        padding: 10px 15px 20px;
        
    }
`;

const InfoWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    padding: 15px;
    align-items: center;
    //background-color: blanchedalmond;
`;

const InfoIcon = styled.div`
    padding-right: 15px;
`;

const InfoContent = styled.div`
`;

const InfoHeading = styled.h2`
    font-size: 1rem;
`;

const InfoFigure = styled.p`
`

export const InfoCard = (props) => {
    return (
        <InfoWrapper>
            <InfoIcon>
                <Avatar style={{backgroundColor: props.icon[1]}}>{ props.icon[0] }</Avatar>
            </InfoIcon>
            <InfoContent>
                <InfoHeading>{ props.heading }</InfoHeading>
                <InfoFigure>{ props.figure }</InfoFigure>
            </InfoContent>
        </InfoWrapper>
    );
}