// Author: Gary
// url: /dashboard
import React from 'react';
import {
    PageFrame,
    PageTitle,
    PageTitleContent,
    PageHr
} from '../PageTemplate';
import Card from '../../components/Card/Card';
import { ThreeColRow }  from '../../components/Row/Row';
import './DashboardPage.css';

function DashboardPage() {
    return (
        <PageFrame>
            <PageTitle>
                <PageTitleContent>Dashboard</PageTitleContent>
                <PageHr />
            </PageTitle>
            <ThreeColRow grey={ true } columns={ '1fr 1fr 1fr' }>
                <Card heading={ 'Number of CIRA Nodes' }>Hi</Card>
                <Card heading={ 'CIRA Nodes' } />
                <Card heading={ 'CIRA Nodes' } />
            </ThreeColRow>
            
        </PageFrame>
    );
}

export default DashboardPage;
