// Reference: https://material-ui.com/components/progress/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CompletionIcon, LoadingIcon } from './ProgressElements';
import { ModalCover } from '../Modal/ModalElements';

// this is to show the completeness of a received image (# packet num - # packet loss ) / # packet num
export function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

// the loading page
export function LoadingPage(props) {
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCompleted(true);
      window.location.href = props.redirectURL;
    }, 3000);
  })

  return (
    <ModalCover showModal={true}>
      {
        !completed && (
          <LoadingIcon>
            <CircularProgress style={{ height: '100%', width: '100%' }} />
          </LoadingIcon>
        )
      }
      {
        completed && (
          <CompletionIcon>
            <CheckCircleIcon style={{ height: '100%', width: '100%', color: '#4CAF50', fontSize: 130 }} />
          </CompletionIcon>
        )
      }
    </ModalCover>
  );
}
