import styled from 'styled-components';

export const dateDecoder = (date) => {
    if (typeof(date) === 'string') {
        let _MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = date.substring(0, 4);
        let month = parseInt(date.substring(5, 7));
        let day = date.substring(8, 10);
        //let time = date.substring(11);
        //return `${ day } ${ _MONTH[month-1] } ${ year } at ${ time }`; 
        return `${ day } ${ _MONTH[month-1] } ${ year }`; 
    }
    /**
    if (typeof(date) === 'string') {
        return `${ date.substring(0, 10) }`; 
    }*/
}

// export const textconverter = (text) => {
//     let result = [];
//     for ( let i = 0; i < text.length; i += 2)
//     {
//         result.push('0x' + text[i] + text[i+1] + ',')
//     }

//     return result;
// }

export const PageFrame = styled.div`
    margin-top: 80px;
    width: 100%;
`;

export const PageTitle = styled.h1`
    padding: 50px 100px 0;
    font-size: 2.5rem;
    background-color: #f7f7f7;

    @media screen and (max-width: 945px)  {
        text-align: center;
        font-size: 2rem;
        padding: 50px 70px 0;
    }

    @media screen and (max-width: 480px) {
        padding: 15px;
    }
`;

export const PageTitleContent = styled.div`
    width: 100%;
    display: flex;

    @media screen and (max-width: 945px)  {
        align-items: center;
        justify-content: center;
    }
`;

export const PageTitleDescription = styled.p`
    font-size: 1.5rem;
`;

export const PageSubtitle = styled.h2`
    padding: 0 100px;
    font-size: 1.5rem;
    font-weight: 100;
    background-color: #f7f7f7;

    @media screen and (max-width: 945px) {
        text-align: center;
    }
`;

export const PageHr = styled.hr`
    height: 0;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
