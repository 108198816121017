// Reference: https://material-ui.com/components/steppers/#stepper
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return ['Enable your Wi-Fi Hotspot', 'Power on the CIRA device', 'Go to CIRA operator'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <>
          <div>Network Name: <b>Sengital-cira</b></div>
          <div>Password: <b>ciracira</b></div>
        </>
      );
    // case 1:
    //   return 'An ad group contains one or more ads which target a shared set of keywords.';
    // case 2:
    //   return `Good`;
    // default:
    //   return 'Unknown step';
  }
}

export default function ProgressStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const deviceId = props.deviceId;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const redirect = () => {
    window.open(`http://192.168.43.31/?id=${ deviceId }`);
  };

  return (
    <div>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  
                  {activeStep === steps.length - 1 ? 
                  <Button
                    variant="contained"
                    color="primary"
                    onClick= { () => {handleNext(); redirect();}}
                    className={classes.button}
                  >
                    Finish
                  </Button> : 
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>}
      
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}