import styled from 'styled-components';

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const TitleIcon = styled.div`
    padding-right: 10px;
    line-height: 0;
`;

export const PreviewImageContainer = styled.div`
    height: 250px;
    width: 100%;
    display: flex;
    padding: 10px;
    justify-content: center;

    @media screen and (max-width: 480px) {
        height: 200px;
    }
`;

export const PreviewImage = styled.img`
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 5px 5px 5px rgba(0,0,0,.2);
    
    @media screen and (max-width: 480px) {
        max-height: 200px;
    }
`;

export const PreviewImageInputContainer = styled.div`
    padding: 10px 0;
`;

export const RegionPickerContainer = styled.div`
    padding: 20px 0;
`;

export const QRCodeDisplayContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const WarningMessage = styled.div`
    min-height: 50px;
    padding: 10px 0 0;
    font-size: smaller;
    color: red;
`;

export const ActivationAppFrameContainer = styled.div`
    display: flex;
    height: 500px;
    width: 600px;
    padding: 10px 0;

    @media screen and (max-width: 480px) {
        padding: 0;
    }
`

export const StepperWrapper = styled.div`
    flex: 30;

    @media screen and (max-width: 480px) {
        display: none;
    }
`;

export const ActivationAppFrameWrapper = styled.div`
    flex: 70;
`;

export const ActivationAppFrame = styled.iframe`
    height: 500px;
    width: 100%;
    border: 1px solid #000;
    border-radius: 10px;

    @media screen and (max-width: 480px) {
        height: 520px;
        border: 0;
    }
`;