import styled from 'styled-components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SimpleMenu from '../../components/Menu/Menu'
import Tooltip from '@material-ui/core/Tooltip';

export const BackToMainPageBar = styled.div`
    height: 30%;
    //width: 100%;
    left: -100%;
    display: flex;
    position: absolute;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    background-color: #EAECEE;
    transition: all .2s ease-in-out;
`;

export const BackToMainPageBarContainer = styled.div`
    height: 100%;
	width: 80px;
    top: 0;
	left: 0;
    display: flex;
	position: fixed;
    align-items: center;
    justify-content: center;
	background-color: transparent;

    &:hover ${BackToMainPageBar} {
        left: 0
    }

    @media screen and (max-width: 480px) {
        display: none;
    }
`;

export const BackToMainPageButtonContainer = styled.div`
    display: none;

    @media screen and (max-width: 480px) {
        display: flex;
        position: absolute;
        top: 90px;
        right: 355px;
    }
`;

export const BackToMainPageButton = () => {
    return (
        <BackToMainPageButtonContainer>
                <Tooltip title="Previous">
                    <IconButton onClick={ () => window.location.href = "/devices" }>
                        <ArrowBackIosIcon />
                    </IconButton>
                </Tooltip>
        </BackToMainPageButtonContainer>
    );
}

export const ReminderTextContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 100px;

    @media screen and (max-width: 945px) {
        display: ${ ({ flex }) => flex ? 'flex' : 'grid' };
        padding: 20px 70px;
        justify-content: center;
    }

    @media screen and (max-width: 480px) {
        padding: 15px;
    }
`;

export const ReminderTextWrapper = styled.div`
    display: flex;

    @media screen and (max-width: 945px) {
        padding-bottom: ${ ({ flex }) => flex ? '0' : '15px' };
    }
`;

export const ReminderText = styled.h2`
    padding: 0 10px;
    font-size: 1.2rem;
    font-weight: 100;
`;

export const FunctionMenuContainer = styled.div`
    @media screen and (max-width: 945px) {
        position: absolute;
        top: 120px;
        right: 50px;
    }

    @media screen and (max-width: 480px) {
        position: absolute;
        top: 90px;
        right: 10px;
    }
`;

export const ButtonsContainer = styled.div`
    width: 100%;
    display: flex;
    padding-top: 20px;
    justify-content: center;
`;

export const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 30px;
`;

export const PageSwipingContainer = styled.div`
    height: 130px;
    width: 100%;
`;

export const SwipingPage = styled.div`
    height: 100%;
    width: 100%;
`;

export const PageHeading = styled.h2`
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 100;
    text-align: center;
`;

export const FunctionMenu = (props) => {
    return (
        <FunctionMenuContainer>
            <SimpleMenu menuList={ props.menuItems }>
                <Tooltip title="Functions">
                    <IconButton>
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
            </SimpleMenu>
        </FunctionMenuContainer>
    );
}

