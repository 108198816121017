//Reference: https://material-ui.com/components/chips/
import React from 'react';
import Chip from '@material-ui/core/Chip';

export default function OutlinedChips(props) {
    
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    return (
        <Chip
            icon={props.icon}
            deleteIcon={props.deleteIcon}
            label={props.label}
            color="primary"
            onClick={handleClick}
            onDelete={ props.controlFunction }
        />
    );
}